.loader{
    position: absolute;
    width: 64px;
    height: 64px;
    border-radius: 50%;
    top: calc(50% -32px);
    left: calc(50% -32px);
    perspective: 800px;
}

.one{
    position: absolute;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    left: 0%;
    top: 0%;
    animation: rotate-one 1s linear infinite;
    border-top: 3px solid lightseagreen;
}

.two{
    position: absolute;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    left: 0%;
    top: 0%;
    animation: rotate-two 1s linear infinite;
    border-top: 3px solid white;
}

.three{
    position: absolute;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    left: 0%;
    top: 0%;
    animation: rotate-three 1s linear infinite;
    border-top: 3px solid white;
}

@keyframes rotate-one {
    0% { transform: rotateX(35deg) rotateY(-45deg) rotateZ(0deg); }
    0% { transform: rotateX(35deg) rotateY(-45deg) rotateZ(360deg); }
}

@keyframes rotate-two {
    0% { transform: rotateX(50deg) rotateY(10deg) rotateZ(0deg); }
    0% { transform: rotateX(50deg) rotateY(10deg) rotateZ(360deg); }
}

@keyframes rotate-three {
    0% { transform: rotateX(35deg) rotateY(-55deg) rotateZ(0deg); }
    0% { transform: rotateX(35deg) rotateY(-55deg) rotateZ(360deg); }
}